.contactUsPage {
  display: flex;
  flex-direction: column;
}

.contactUsPageImage {
  height: 55vh;
  margin-top: 15vh;
  background-image: url("https://images.unsplash.com/photo-1467664631004-58beab1ece0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80");
  background-size: cover;
}

.contactUsContainer {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contactUsTile {
  width: 25%;
  height: 65%;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactUsPageIcon {
  color: white;
}

.child {
  position: absolute;
  top: 0;
  margin-top: -4vh;
  left: 8.7vw;
  background-color: blue;
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactUsTile h3 {
  text-transform: capitalize;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
}

.contactUsTile p {
  width: 80%;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.officeDetail {
  box-shadow: 0 8px 16px rgb(185, 181, 181, 0.6);
  background-color: rgb(241, 241, 239);
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 1rem;
}
