@media screen and (max-width: 1050px) {
  html body .App {
    display: none;
    content: "Dev in progress";
  }

  html body::before {
    content: "Open website on Desktop.";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-decoration: underline;
  }
}
.App {
  text-align: center;
  background-color: #fff;
  /* background-image: linear-gradient(to right, #dae2f8, #d6a4a4); */
}
