@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  /* background-color: rgb(116, 165, 255); */
  background-image: linear-gradient(to right, #7474bf, #348ac7);

  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
}

.logo-header {
  height: 15vh;
  /* background-color: white; */
  /* box-shadow: 0 8px 6px -6px black; */
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
  display: flex;
}

.logo-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  height: 100%;
}

.logo-text section {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 100%;
  word-break: break-all;
  justify-content: center;
  align-items: center;
}

.logo-text section p,
.logo-text section h1 {
  width: 100%;
  margin: 0;
  text-align: justify;
  /* text-transform: uppercase; */
}

.name {
  /* font-size: 2rem; */
  color: white;
}

.firm {
  color: white;
  font-size: 1.5rem;
}

.numberISO {
  font-size: 1.2rem;
}

.icons-routes {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icons-routes div {
  width: 15%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 1rem;
  /* text-transform: uppercase; */
  white-space: nowrap;
  cursor: pointer;
  color: white;
}

.icons-routes div:hover {
  background-color: #c70039;
  color: white;
}
.icons-routes div:hover .home {
  color: white;
}
.home {
  color: white;
}

.dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 180px;
  top: 100%;
  bottom: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: max-content;
  margin-top: -1rem;
}

.dropdown .dropdown-content-media {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  /* top: 100%; */
  /* bottom: 69%; */
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 1rem;
}

.dropdown-content span:nth-child(1),
.dropdown-content-media span:nth-child(1) {
  border-top: none;
}

.dropdown-content span,
.dropdown-content-media span {
  width: 100%;
  float: none;
  color: black;
  text-decoration: none;
  /* text-align: center; */
  height: 2.5rem;
  left: 10%;
  margin: auto;
  padding-top: 12px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.dropdown-content span:hover,
.dropdown-content-media span:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: flex;
  background-color: white;
}

.dropdown:hover .dropdown-content-media {
  display: flex;
  background-color: white;
}

.dropdown {
  float: left;
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
