.homepage {
  /* margin-top: 15vh; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.twitter {
  position: fixed;
  top: 25vh;
  left: 0;
  z-index: 10;
  color: #50abf1;
  cursor: pointer;
}

.facebook {
  position: fixed;
  top: 35vh;
  left: 0;
  z-index: 10;
  color: #4867aa;
  cursor: pointer;
}

.linkedin {
  position: fixed;
  top: 45vh;
  left: 0;
  z-index: 10;
  color: #2877b5;
  cursor: pointer;
}

.phoneNumbertile {
  position: fixed;
  right: 0;
  top: 25vh;
  height: 5vh;
  border-bottom-left-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
  opacity: 0.9;
  z-index: 10;
}

.phoneNumbertile:hover,
.emailtile:hover {
  opacity: 1;
}

.emailtile {
  position: fixed;
  right: 0;
  top: 33vh;
  height: 5vh;
  border-bottom-left-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
  opacity: 0.9;
  z-index: 10;
}

.welcome-section {
  display: flex;
  flex-direction: row;
  /* background-color: rgba(76, 148, 216, 0.973); */
  font-family: Georgia, "Times New Roman", Times, serif;
  color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.img-slider {
  display: flex;
  width: 100%;
  height: 30vh;
  position: relative;
  overflow-y: hidden;
  margin-top: 15vh;
  /* border-bottom-left-radius: 1rem; */
}

.img-slider .slider-container {
  position: absolute;
  width: 300%;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  animation: slider 26s infinite alternate;
}
.img-slider .slider-container .slide {
  position: relative;
  width: 33.33333333%;
  height: 100%;
  float: left;
  overflow: hidden;
}
.img-slider .slider-container .slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.welcome-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 0 1rem;
  font-weight: bold;
  color: black;
}

.welcome-text p:nth-child(1) {
  font-size: 32px;
}

.welcome-text p:nth-child(2) {
  text-align: justify;
}
@keyframes slider {
  0%,
  35% {
    left: 0;
  }

  40%,
  65% {
    left: -100%;
  }

  70%,
  85%,
  100% {
    left: -200%;
  }
}

.services-container {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-wrap: wrap;
}

.service-divider-one,
.service-divider-two {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.services-container .service-divider-one section,
.services-container .service-divider-two section {
  width: 30%;
  height: 90%;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 0.4rem;
}

.service-cintainer-icon {
  color: white;
}

.services-container section h3 {
  font-weight: bold;
}

.services-container section p {
  margin-top: 0;
}

.services-container .service-divider-one section:nth-child(1) {
  background-color: #fbd786;
}

.services-container .service-divider-one section:nth-child(2) {
  background-color: #77b3d4;
}

.services-container .service-divider-one section:nth-child(3) {
  background-color: #5cb85c;
}

.services-container .service-divider-two section:nth-child(1) {
  background-color: #e0995e;
}
.services-container .service-divider-two section:nth-child(2) {
  background-color: #f7797d;
}
.services-container .service-divider-two section:nth-child(3) {
  background-color: #2193b0;
}

.services-container section:hover {
  width: 32%;
  height: 100%;
}

.services-container .service-divider-one section:nth-child(1):hover {
  background-color: #e0995e;
}

.services-container .service-divider-one section:nth-child(2):hover {
  background-color: #76c2af;
}

.services-container .service-divider-one section:nth-child(3):hover {
  background-color: #8dce8d;
}

.services-container .service-divider-two section:nth-child(1):hover {
  background-color: #d1413f;
}

.about-container {
  width: 100%;
  height: 30vh;
  background-image: url("https://images.unsplash.com/photo-1494797710133-75ad43b1cd95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80");
  opacity: 0.8;
  background-attachment: fixed;
  background-size: cover;
}

.about-container {
  display: flex;
  flex-direction: column;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.about-container p:nth-child(1) {
  font-size: 2.5rem;
  color: rgb(252, 252, 2);
  font-weight: bold;
  height: 40%;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.about-container p:nth-child(2) {
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
  height: 70%;
}

.media-container {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
}

.media-container h1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 2rem;
}

.mediaTiles {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

hr {
  width: 80%;
  color: black;
}

.mediaTiles section {
  width: 30%;
  height: 70%;
  background-color: teal;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  border-radius: 0.5rem;
}

.mediaTiles section:nth-child(1) {
  background-image: url("https://www.asme.org/getmedia/81ffb58e-9fc4-40f1-ba08-b816ce2faebb/guide-to-conference-publications.jpg?width=450&height=297&ext=.jpg");
  background-position: left;
}

.mediaTiles section:nth-child(2) {
  background-image: url("https://post.healthline.com/wp-content/uploads/2020/08/AN275-cup-of-coffee-732x549-Thumb_0-1-732x549.jpg");
  /* background-repeat: no-repeat; */
  background-size: cover;
}

.mediaTiles section:nth-child(3) {
  background-image: url("https://onlinelearninginsights.files.wordpress.com/2016/01/news.gif");
  background-size: cover;
}

.mediaTiles section p {
  font-size: 1.4rem;
  background-color: black;
  opacity: 0.8;
  color: rgb(223, 223, 47);
  width: 100%;
  height: 20%;
  margin: 0;
  font-weight: bold;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.mediaTiles section:hover {
}

.form-container {
  background-color: white;
  width: 100%;
  height: 70vh;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.form-child {
  margin: 30px 90px;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}

.form-child label {
  color: #ff1000;
  font-weight: bold;
  font-size: 24px;
}

.form-child label::after {
  height: 2px;
  width: 120px;
  background-color: #ff1000;
  content: "";
  display: inline-block;
  margin: 4px 10px;
}

.form-container p {
  color: #ff1000;
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0;
}

.form-filling {
  width: 70%;
  height: 100%;
}

.form-address-details {
  width: 30%;
  height: 100%;
  /* background-color: black; */
}

.form-detail-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.enquiry-form-inputs {
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: space-around;
  align-items: center;
}

.enquiry-form-inputs input {
  width: 40%;
  height: 40%;
  border: none;
  border: 0.125rem solid #f0efef;
  color: #5e5d5d;
  font-size: 20px;
  outline: none;
  padding: 8px;
}

.form-filling .mobileNumberInput {
  width: 90%;
  height: 40px;
  color: #5e5d5d;
  font-size: 20px;
  border: 0.125rem solid #f0efef;
  padding: 8px;
}

::placeholder {
  padding-left: 4px;
  color: #5e5d5d;
}

.enquiry-form-textarea {
  margin-top: 16px;
  width: 90%;
  height: 25%;
  outline: none;
  border: 1px solid #f0efef;
  color: #5e5d5d;
  font-size: 1.2rem;
  padding: 8px;
  resize: none;
}

.form-container button {
  width: 20%;
  height: 15%;
  margin: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #f0efef;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  font-size: 1.2rem;
  color: #5e5d5d;
}
.form-container button:hover {
  background-color: #ff1000;
  color: white;
}
