.service-page {
  display: flex;
  flex-direction: column;
}

.service-form-container-image {
  height: 85vh;
  margin-top: 15vh;
  background-image: url("https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1051&q=80");
  background-size: cover;
}

.service-form-container {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-form-container-2 {
  width: 95%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.service-form-container-2 div:nth-child(1) {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-form-container-2 div:nth-child(1) h1 {
  color: darkgoldenrod;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  text-transform: uppercase;
}

.service-form-container-2 div:nth-child(1) p {
  color: #00ffff;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
}

.service-form-container-2 div:nth-child(2) {
  width: 40%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #00ffff;
}

.service-form-container-2 div:nth-child(2) h2 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  text-transform: uppercase;
}

.service-form-container-2 div:nth-child(2) h2:nth-child(1) {
  margin-bottom: 0;
}

.service-form-container-2 div:nth-child(2) h2:nth-child(2) {
  margin-top: 0;
}

.service-form-container-2 div:nth-child(2) input {
  width: 80%;
  height: 8%;
  border: none;
  border-bottom: 1px solid white;
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  color: #00ffff;
}

.service-form-container-2 div:nth-child(2) input::placeholder {
  color: #00ffff;
}

.service-form-container-2 div:nth-child(2) button {
  width: 80%;
  height: 6%;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0);
  color: #00ffff;
  border: 1px solid white;
  border-radius: 1rem;
  cursor: pointer;
  outline: none;
}

.service-form-container-2 div:nth-child(2) button:hover {
  background-color: rgba(255, 255, 255, 1);
  color: black;
}

.stepscontainer {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepsDiv {
  width: 20%;
  height: 60%;
  border: none;
  color: black;
}

.stepsDiv p {
  text-align: center;
}

.stepsIcon {
  color: #330000;
}

.stepsDiv:nth-child(1) {
  animation: step1 2s 1;
  animation-delay: 1s;
}

.stepsDiv:nth-child(2) {
  animation: step2 3s 1;
  animation-delay: 1s;
}

.stepsDiv:nth-child(3) {
  animation: step3 4s 1;
  animation-delay: 1s;
}

.stepsDiv:nth-child(4) {
  animation: step4 5s 1;
  animation-delay: 1s;
}

@keyframes step1 {
  0% {
    opacity: 0;
    transform: translateX(-20rem) scale(1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes step2 {
  0% {
    opacity: 0;
    transform: translateY(-20rem) scale(1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes step3 {
  0% {
    opacity: 0;
    transform: translateY(20rem) scale(1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes step4 {
  0% {
    opacity: 0;
    transform: translateX(20rem) scale(1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
