.footer {
  width: 100%;
  height: 25vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.footer h1 {
  width: 90%;
  text-align: left;
}
.map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}
.iframe {
  width: 40%;
  height: 100%;
  border: 0;
}
.address {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 3%;
}
.address h5 {
  margin: 10px 0;
}
.footer h5 {
  width: 90%;
  text-align: left;
}
.footer hr {
  width: 90%;
}
.footer p {
  margin-top: 0;
  width: 90%;
  text-align: left;
}
.copyright {
  background-color: black;
  width: 100%;
  height: 10%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyright h5 {
  text-align: center;
}
