.aboutUsPage {
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
}

.aboutuspageimage {
  background-image: url("https://images.unsplash.com/photo-1502920514313-52581002a659?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1047&q=80");
  background-size: cover;
  height: 35vh;
  background-color: rgb(194, 142, 142);
  background-blend-mode: screen;
  display: flex;
  color: #000033;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Georgia, "Times New Roman", Times, serif;
  /* animation: animate 10s infinite alternate; */
}

.aboutuspageimage h1 {
  animation: animate 5s 1 alternate;
}

.aboutuspageimage p {
  animation: animateP 5s 1 alternate;
  font-weight: bold;
  width: 70%;
}

.descriptionContainers {
  width: "100%";
  display: "flex";
  justify-content: "space-around";
  align-content: "center";
  flex-direction: "column";
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(-4rem) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes animateP {
  0% {
    opacity: 0;
    transform: translateX(-20rem) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
